.container {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    &Image {
        height: 60px;
    }

    &Text {
        font-size: rem-calc(30px);
        display: flex;
        align-items: center;
        margin-left: 25px;
    }
}
