.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #282f37;
    padding: 15px;
    border-radius: 10px;
    align-self: center;
    width: 100%;
}
