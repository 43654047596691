@import "variables.modules";
@import "mixins.modules";
// SIMPLE GRID - SASS/SCSS

// utility

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.justify {
    text-align: justify;
}

.mobile {
    @include breakpoint(md) {
        display: none !important;
    }
}

.mobile-tablet {
    @include breakpoint(lg) {
        display: none !important;
    }
}

.tablet {
    display: none !important;

    @include breakpoint(md) {
        display: inline-block !important;
    }
}

.desktop {
    @include breakpoint-max(lg) {
        display: none !important;
    }
}

.container-fluid,
.container {
    margin-right: auto;
    margin-left: auto;
}

.container-fluid {
    padding-right: $gutter-width;
    padding-left: $gutter-width;
}

.container {
    width: 100%;
    max-width: $container-lg;
    padding-right: $gutter-width;
    padding-left: $gutter-width;

    &--narrow {
        width: 600px;
        max-width: 100%;
    }
}

.row {
    @include breakpoint(lg) {
        .row {
            &--gutter {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $negative-gutter;
    margin-left: $negative-gutter;

    &--gutter {
        padding-right: $gutter-width;
        padding-left: $gutter-width;
    }

    &--middle {
        align-items: center;
    }

    &--no-gutter {
        margin-right: 0;
        margin-left: 0;
    }

    &--double-no-gutter-mobile {
        @include breakpoint-max(lg) {
            margin-right: $negative-double-gutter;
            margin-left: $negative-double-gutter;
        }
    }

    &--double-no-gutter-desktop {
        @include breakpoint(lg) {
            margin-right: $negative-double-gutter;
            margin-left: $negative-double-gutter;
        }
    }

    &--reverse {
        flex-direction: row-reverse;
    }
}

.col.reverse {
    flex-direction: column-reverse;
}

%col-xs-styles {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
}

@mixin col-xs-list {
    @for $i from 0 through $columns {
        @if $i == 0 {
            .col-xs,
            .col-xs-offset-#{$i} {
                @extend %col-xs-styles;
            }
        } @else {
            .col-xs-#{$i},
            .col-xs-offset-#{$i} {
                @extend %col-xs-styles;
            }
        }
    }
}

@include col-xs-list;

.col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

@for $i from 1 through $columns {
    $width: $i / $columns * 100%;

    .col-xs-#{$i} {
        flex-basis: $width;
        max-width: $width;
    }
}

@for $i from 1 through $columns {
    $offset: ($i - 1) / $columns * 100%;

    .col-xs-offset-#{$i - 1} {
        margin-left: $offset;
    }
}

@for $i from 1 through $columns {
    .order-xs-#{$i} {
        order: $i;
    }
}

.flex-fluid {
    flex-grow: 1;
}

.start-xs {
    justify-content: flex-start;
    text-align: start;
}

.center-item-xs {
    @include breakpoint-max(lg) {
        justify-content: center;
    }
}

.center-xs {
    justify-content: center;
    text-align: center;
}

.end-xs {
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    align-items: flex-start;
}

.middle-xs {
    align-items: center;
}

.bottom-xs {
    align-items: flex-end;
}

.around-xs {
    justify-content: space-around;
}

.between-xs {
    justify-content: space-between;
}

.first-xs {
    order: -1;
}

.last-xs {
    order: 1;
}

.inline-flex {
    display: inline-flex;
}

.flex,
.flex-xs {
    display: flex;
}

.block,
.block-xs {
    display: block;
}

@media only screen and (min-width: $screen-md-min) {
    %col-md-styles {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: $half-gutter-width;
        padding-left: $half-gutter-width;
    }

    @mixin col-md-list {
        @for $i from 0 through $columns {
            @if $i == 0 {
                .col-md,
                .col-md-offset-#{$i} {
                    @extend %col-md-styles;
                }
            } @else {
                .col-md-#{$i},
                .col-md-offset-#{$i} {
                    @extend %col-md-styles;
                }
            }
        }
    }

    @include col-md-list;

    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    @for $i from 1 through $columns {
        $width: $i / $columns * 100%;

        .col-md-#{$i} {
            flex-basis: $width;
            max-width: $width;
        }
    }

    @for $i from 1 through $columns {
        $offset: ($i - 1) / $columns * 100%;

        .col-md-offset-#{$i - 1} {
            margin-left: $offset;
        }
    }

    @for $i from 1 through $columns {
        .order-md-#{$i} {
            order: $i;
        }
    }

    .start-md {
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        justify-content: center;
        text-align: center;
    }

    .end-md {
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        align-items: flex-start;
    }

    .middle-md {
        align-items: center;
    }

    .bottom-md {
        align-items: flex-end;
    }

    .around-md {
        justify-content: space-around;
    }

    .between-md {
        justify-content: space-between;
    }

    .first-md {
        order: -1;
    }

    .last-md {
        order: 1;
    }

    .flex-md {
        display: flex;
    }

    .block-md {
        display: block;
    }
}

@media screen and (max-width: $screen-lg-min) {
    .hidden-xs {
        display: none;
    }
}

@media only screen and (min-width: $screen-lg) {
    %col-lg-styles {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: $half-gutter-width;
        padding-left: $half-gutter-width;
    }

    @mixin col-lg-list {
        @for $i from 0 through $columns {
            @if $i == 0 {
                .col-lg,
                .col-lg-offset-#{$i} {
                    @extend %col-lg-styles;
                }
            } @else {
                .col-lg-#{$i},
                .col-lg-offset-#{$i} {
                    @extend %col-lg-styles;
                }
            }
        }
    }

    @include col-lg-list;

    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    @for $i from 1 through $columns {
        $width: $i / $columns * 100%;

        .col-lg-#{$i} {
            flex-basis: $width;
            max-width: $width;
        }
    }

    @for $i from 1 through $columns {
        $offset: ($i - 1) / $columns * 100%;

        .col-lg-offset-#{$i - 1} {
            margin-left: $offset;
        }
    }

    @for $i from 1 through $columns {
        .order-lg-#{$i} {
            order: $i;
        }
    }

    .start-lg {
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        align-items: flex-start;
    }

    .middle-lg {
        align-items: center;
    }

    .bottom-lg {
        align-items: flex-end;
    }

    .around-lg {
        justify-content: space-around;
    }

    .between-lg {
        justify-content: space-between;
    }

    .first-lg {
        order: -1;
    }

    .last-lg {
        order: 1;
    }

    .flex-lg {
        display: flex;
    }

    .block-lg {
        display: block;
    }
}

.no-gutter--mobile {
    padding-left: 0;
    padding-right: 0;
    @include breakpoint(lg) {
        padding-right: $half-gutter-width;
        padding-left: $half-gutter-width;
    }
}

.no-gutter--desktop {
    @include breakpoint(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.no-gutter--tablet {
    @include breakpoint(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.flex-block {
    display: flex;
    padding-left: $half-gutter-width;
    padding-right: $half-gutter-width;

    @include breakpoint(md) {
        padding-left: $quarter-gutter-width;
        padding-right: $quarter-gutter-width;
    }

    &.xs-flex-block--left {
        margin-left: 0;
        margin-right: auto;
    }

    @include breakpoint(md) {
        &.xs-flex-block--left {
            margin-right: 0;
        }
    }
}

.sectionTitle {
    font-weight: bold;
    font-size: 24px;
}

.bold {
    @include fontWeight(bolded);
}
