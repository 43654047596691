@import "../../../assets/styles/variables.modules";
@import "../../../assets/styles/mixins.modules";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;

    &Title {
        @include texts(large);
        @include fontWeight(bolded);

        margin: 20px 0;
        align-self: flex-start;
    }

    &Input {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        span {
            @include texts(big);
            @include fontWeight(large);
        }

        input {
            height: 40px;
        }
    }

    &TextArea {
        position: relative;
        width: 100%;

        textarea {
            width: 96%;
            height: 70px;
            margin-bottom: 15px;
        }

        &Error {
            position: absolute;
            color: red;
            bottom: -2px;
            left: 0;
        }
    }

    &Buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
}
