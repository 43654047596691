@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.footer {
    display: none;
    flex-direction: column;
    justify-content: center;
    page-break-before: always;

    @media print {
        position: relative;
        width: 90%;
        display: flex;
        align-self: center;
        margin-top: 90px;
    }

    &Label,
    &Magzine {
        @include fontWeight(bolded);

        width: 100%;
        display: flex;
        background: lightgrey;
        justify-content: center;
        border: 1px solid black;
        height: 40px;
        align-items: center;
    }

    &Magzine {
        margin-top: 30px;
    }

    &Items {
        display: flex;

        div {
            @include texts(big);

            width: 50%;
            border: 1px solid black;
            padding-left: 5px;
            padding-top: 5px;
            height: 40px;
        }
    }

    &SingleItem {
        display: flex;

        div {
            @include texts(big);

            padding-left: 5px;
            padding-top: 5px;
            width: 100%;
            border: 1px solid black;
            height: 40px;
        }
    }
}
