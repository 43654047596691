@import "../../../assets/styles/variables.modules";
@import "../../../assets/styles/mixins.modules";

.header {
    display: flex;
    border: 1px solid $dark-input-border;
    border-radius: 5px;

    &Description {
        width: 80%;
        display: flex;

        &Number {
            border: 1px solid $dark-input-border;

             div {
                height: 50%;
                justify-content: center;
                align-items: center;
                align-self: center;
                text-align: center;
                 display: flex;
            }
        }

        &Project {
            display: flex;

            div {
                padding: 10px 20px;
            }
        }

        &Textarea {
            padding: 20px 20px 0 20px;
            width: 100%;
            font-size: rem-calc(26);
        }

        &Title {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $dark-nice;
            width: 172px;
        }
    }

    &TypeWrapper {
        width: 20%;
    }

    textarea {
        width: 98%;
        font-size: rem-calc(26);
    }
}

.wrapper {
    width: 100%;

    &Title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &Header {
            margin-left: 30px;
        }

        &Button {
            margin-top: 50px;
            display: flex;
            justify-content: center;

            button {
                margin: 0 30px;
            }
        }
    }
}

.desc {
    display: flex;
    width: 100%;

    &Num {
        display: flex;
        width: 40%;
        margin-top: 20px;

        &Wrapper {
            width: 100%;

            &Header {
                border: 1px solid $dark-input-border;
                height: 110px;
                display: flex;
                align-items: center;
                text-align: center;
                background: $dark-nice;
                justify-content: center;
            }

            &Inputs {
                border: 1px solid $dark-input-border;
                display: flex;
                height: 110px;
                justify-content: center;
                align-items: center;
            }

            &Link {
                text-decoration: underline;
            }
        }
    }

    &Props {
        width: 40%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        &Wrapper {
            display: flex;

            &Header {
                border: 1px solid $dark-input-border;
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                min-width: 50px;
                max-width: 150px;
                width: 100%;
                background: $dark-nice;
            }

            &Textarea {
                border: 1px solid $dark-input-border;
                padding: 10px;
                width: 100%;
                height: 90px;
                overflow-y: auto;
                @include scroll();
            }
        }
    }

    &Selects {
        margin-top: 20px;
        width: 20%;
        display: flex;
        flex-direction: column;

        &Wrapper {
            width: 100%;

            &Header {
                border: 1px solid $dark-input-border;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: $dark-nice;
            }

            &Select {
                display: flex;
                height: 55px;
                justify-content: center;
                width: 100%;
                align-items: center;
                border: 1px solid $dark-input-border;
            }

            &Date {
                height: 52px;
            }
        }
    }
}

.default {
    &Wrapper {
        margin-top: 20px;
    }

    &Recipes {
        display: flex;
    }

    &Inputs {
        display: flex;
        align-items: center;

        &Value {
            border: 1px solid $dark-input-border;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13%;
            background: $dark-nice;
        }

        &Label {
            border: 1px solid $dark-input-border;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 20px;
            width: 87%;
        }

        &Link {
            padding: 0 10px;

            a {
                text-decoration: underline;
            }
        }
    }
}
