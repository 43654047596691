.buttons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    button {
        width: 210px;
    }
}

.wrapper {
    &Button {
        display: flex;
        justify-content: center;
        margin: 30px 0 100px;
        height: 35px;

        button {
            margin: 0 20px;
        }
    }
}
