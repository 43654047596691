@import "../../../assets/styles/mixins.modules";
@import "../../../assets/styles/mixins.modules";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 1px solid $dark-input-border;
    border-radius: 5px;

    &Inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 15px 0;

        @include breakpoint(lg) {
            justify-content: space-between;
        }

        input {
            margin: 10px 20px;

            @include breakpoint(lg) {
                flex: 1;
            }
        }
    }
}
