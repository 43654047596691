@import "../../../assets/styles/variables.modules";
@import "../../../assets/styles/mixins.modules";

.header {
    display: flex;
    border: 1px solid $dark-input-border;
    border-radius: 5px;

    &Number {
        border-right: 1px solid $dark-input-border;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        div {
            height: 50%;
            justify-content: center;
            align-items: center;
        }

        input {
            width: 80%;
        }
    }

    &Description {
        width: 80%;
        display: flex;

        &Textarea {
            padding: 20px 20px 0 20px;
            width: 100%;
        }

        &Title {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $dark-nice;
            width: 172px;
        }
    }

    &TypeWrapper {
        width: 20%;
    }

    textarea {
        width: 98%;
        font-size: rem-calc(26);
    }
}

.wrapper {
    width: 100%;

    &Title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &Header {
            margin-left: 30px;
        }
    }
}

.desc {
    display: flex;
    width: 100%;

    &Num {
        display: flex;
        width: 40%;
        margin-top: 20px;

        &Wrapper {
            width: 100%;

            &Header {
                border: 1px solid $dark-input-border;
                height: 110px;
                display: flex;
                align-items: center;
                text-align: center;
                background: $dark-nice;
            }

            &Inputs {
                border: 1px solid $dark-input-border;

                input {
                    width: 100%;
                    height: 50px;
                }

                > div {
                    width: 86%;
                    display: flex;
                    margin: 29px auto;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    &Props {
        width: 40%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        &Wrapper {
            display: flex;

            &Header {
                border: 1px solid $dark-input-border;
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                min-width: 50px;
                max-width: 150px;
                width: 100%;
                background: $dark-nice;
            }

            &Textarea {
                border: 1px solid $dark-input-border;
                padding: 10px;
                width: 100%;

                textarea {
                    height: 60px;
                    margin-top: 5px;
                    width: 93%;
                }
            }
        }
    }

    &Selects {
        margin-top: 20px;
        width: 20%;
        display: flex;
        flex-direction: column;

        &Wrapper {
            width: 100%;

            &Header {
                border: 1px solid $dark-input-border;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: $dark-nice;
            }

            &Select {
                display: flex;
                height: 55px;
                justify-content: center;
                width: 100%;
                border: 1px solid $dark-input-border;

                input {
                    margin-top: 10px;
                }
            }

            &Date {
                height: 52px;
            }
        }
    }
}
