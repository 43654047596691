@import "../../../src/assets/styles/mixins.modules";

.categories {
    width: 100%;

    &Wrapper {
        width: 50%;
    }

    &Title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            margin-right: 3px;
        }
    }

    &NewCategory {
        padding-bottom: 20px;
        width: 100%;
    }

    &Content {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    &Edit {
        display: flex;
        flex-direction: column;
        margin: 50px auto;
        text-align: center;
        align-items: center;

        &Name {
            @include texts(2x-large);
            @include fontWeight(bolded);

            padding-bottom: 10px;
        }

        &Description {
            @include texts(big);

            margin-bottom: 5px;
        }

        &Submit {
            margin: 20px 0 10px 0;
        }

        &Form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 200px;
        }
    }

    &List {
        &ItemWrapper {
            display: flex;
            min-height: 34px;
            background: $first-tab-item-color;
            border: 1px solid $first-tab-item-color;
            color: $tab-item-color;
            align-items: center;

            @include fontWeight(normal);
            @include texts(big);

            &:nth-child(odd) {
                background: $second-tab-item-color;
            }

            &:hover {
                @include transition();

                cursor: pointer;
                color: black;
                background: $tab-item-hover;
                opacity: 0.95;
                border: 1px solid lightgrey;
            }

            span {
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0 4px;
            }

            button {
                display: flex;
                align-items: center;
            }

            button {
                @include transition();

                border: none;
                background: none;
                color: #000;
                text-decoration: none;
                font-weight: 700;

                &:hover {
                    @include transition();

                    text-decoration: underline;
                }
            }
        }
    }

    &Header {
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        //background: #362f4b;
        margin-top: 15px;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);

            margin: 0 4px;
        }
    }

    &Name {
        @include fontWeight(bolded);

        width: 60%;
        display: flex;
        align-items: center;

        &Icon {
            width: 15px;
            height: 15px;
            background: white;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    &Index {
        width: 10%;
    }
}
