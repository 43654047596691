@import "../../src/assets/styles/grid.modules";
@import "../../src/assets/styles/mixins.modules";

.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 10px 0 10px 10px;

    input {
        border: 0;
    }

    &Icon {
        height: 15px;
        width: 15px;
    }

    &CloseIcon {
        height: 20px;
        width: 20px;
    }

    &IconWrapper {
        background: lightgrey;
        border-radius: 50px;
        position: absolute;
        left: 145px;
        width: 24px;
        height: 24px;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    &ClearIconWrapper {
        cursor: pointer;
        background: lightgrey;
        border-radius: 50px;
        position: absolute;
        left: -35px;
        width: 24px;
        height: 24px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.pagination {
    margin: 50px 0;
}

.select {
    display: flex;
    align-items: center;
    position: relative;

    &Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    &Header {
        margin-right: 5px;
    }

    &Searcher {
        display: flex;
        min-height: 42px !important;
        height: 42px;
        padding: 0;

        div {
            margin: 0;
        }
    }

    &Input {
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        border: 0;
        background-color: $dark-input-bg;
        border-bottom: 1px solid $dark-input-border;
        text-align: left;
        height: 32px;
        min-width: 170px;
        outline: none;
        color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        width: 170px;

        &::placeholder {
            color: $dark-placeholder-label;
        }

        &:focus {
            background: $dark-input-bg-focus;
            box-shadow: $dark-input-shadow 3px 3px 3px;
        }

        span {
            @include fontWeight(normal);
            @include texts(big);
        }
    }

    &IconList {
        &:after {
            @include texts(large);

            content: "^";
            transform: rotate(180deg);
            right: 5px;
            top: 5px;
            position: absolute;
        }
    }

    &IconCross {
        &:after {
            @include texts(large);

            content: "x";
            right: 5px;
            position: absolute;
            top: 3px;
            padding: 3px;
        }
    }

    &List {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        left: 0;
        top: 33px;
        z-index: 10;
        min-height: 50px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 0;
            background-color: darkgrey;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            background-color: black;
        }

        &Item {
            min-height: 33px;
            width: auto;
            display: flex;
            align-items: center;
            padding-left: 10px;
            background-color: $dark-input-bg-focus;
            border-bottom: 1px solid $dark-input-border;

            &:hover {
                box-shadow: 0 0 0 1px $black inset;

                span {
                    @include fontWeight(bolded);
                }
            }
        }
    }
}
