.overlay {
    background-color: rgba(0, 0, 0, 0.55);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.close {
    background: black;
    height: 20px;
    position: relative;
    width: 2px;
    transform: rotate(45deg);
    margin-right: 5px;

    &:after {
        background: black;
        content: "";
        height: 2px;
        left: -9px;
        position: absolute;
        top: 9px;
        width: 20px;
    }
}

.wrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding: 15px

}

.children {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
