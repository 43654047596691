@import "../../../src/assets/styles/grid.modules";
@import "../../../src/assets/styles/variables.modules";

.ingredients {
    position: relative;
    width: 100%;

    &Title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &Header {
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 15px;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);

            margin: 0 4px;
        }
    }

    &Index {
        width: 3%;
        margin-left: 10px !important;
    }

    &Name {
        @include fontWeight(bolded);

        width: 40%;
        display: flex;
        align-items: center;

        &Icon {
            width: 15px;
            height: 15px;
            background: white;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    &Count {
        width: 20%;
    }

    &Price {
        width: 20%;
    }

    &Density {
        width: 20%;
    }

    &Category {
        width: 30%;
    }

    &List {
        &Item {
            display: flex;
            min-height: 34px;
            color: $tab-item-color;
            align-items: center;

            @include fontWeight(normal);
            @include texts(big);

            span {
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0 4px;
            }

            &:hover {
                @include transition();

                color: $black;
            }
        }
    }

    &Wrapper {
        background: $first-tab-item-color;
        border: 1px solid $first-tab-item-color;

        &:nth-child(odd) {
            background: $second-tab-item-color;
        }

        &:hover {
            @include transition();

            cursor: pointer;
            color: black;
            background: $tab-item-hover;
            opacity: 0.95;
            border: 1px solid lightgrey;
        }
    }

    &Search {
        display: flex;
        justify-content: flex-end;
    }
}
