@import "mixins.modules";

.btn {
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-out,
        color 0.3s ease-in-out;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 8px;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
    border: none;

    &:focus {
        outline: none;
    }

    // COLOR VARIANTS

    &--default {
        background: $buttonDefault;
        border: 1px solid $black;
        color: $black;

        @include fontWeight(bolded);

        &:hover {
            @include breakpoint(lg) {
                @include fontWeight(bolded);

                box-shadow: 0 0 0 1px $black inset;
            }
        }
    }

    &--black {
        background: $black;
        border: 1px solid $black;
        color: $white;

        @include fontWeight(bolded);

        &:hover {
            @include breakpoint(lg) {
                @include fontWeight(bolded);

                box-shadow: 0 0 0 1px $white inset;
            }
        }
    }

    &--green {
        background: $buttonDefault;
        border: 1px solid $editGreenButton;
        color: $black;

        @include fontWeight(bolded);

        &:hover {
            @include breakpoint(lg) {
                @include fontWeight(bolded);

                box-shadow: 0 0 0 1px $white inset;
                border: 3px solid $editGreenButton;
            }
        }
    }

    &--red {
        background: $buttonDefault;
        border: 1px solid $deleteRedButton;
        color: $black;

        @include fontWeight(bolded);

        &:hover {
            @include breakpoint(lg) {
                @include fontWeight(bolded);

                box-shadow: 0 0 0 1px $white inset;
                border: 3px solid $deleteRedButton;
            }
        }
    }

    &--width-large {
        width: 260px;
        max-width: 100%;
    }

    &--width-medium {
        width: 230px;
        max-width: 100%;
    }

    &--height-medium {
        height: 30px;
    }

    &--width-small {
        width: 80px;
        max-width: 100%;
        min-width: unset;
    }
}
