@import "assets/styles/mixins.modules";

.header {
    &Title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            padding-left: 5px;
        }

        &Icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            width: 20px;
            height: 20px;
            background: $iconBackground;
            border-radius: 8px;
            margin-right: 5px;

            img {
                width: 18px;
                height: 18px;
                background: $iconBackground;
            }
        }
    }
}
