@import "assets/styles/mixins.modules";

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &Title {
        margin-left: 30px;
    }
}
