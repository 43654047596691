@import "../../../../src/assets/styles/grid.modules";
@import "../../../../src/assets/styles/mixins.modules";

.sample {
    &Input {
        margin: 0 20px;
        text-align: left;
        width: 20%;
    }

    &Label {
        @include texts(big);
    }

    &TextArea {
        margin-left: 20px;
        text-align: left;

        textarea {
            width: 66%;
        }
    }

    &Buttons {
        width: 70%;

        button {
            margin: 40px 10px 0 10px;
            width: 200px;
        }
    }
}
