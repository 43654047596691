@import "../../assets/styles/variables.modules";
@import "../../assets/styles/mixins.modules";

.wrapper {
    &Width {
        width: 100%;
    }
    &Content {
        font-size: 22px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 20px 0 0;
        border: 1px solid $dark-input-border;
        border-radius: 5px;

        &RecipeName {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0;

            div {
                @include texts(large);
                @include fontWeight(large);

                padding-right: 10px;
                flex: 1.5;

                @include breakpoint(lg) {
                    flex: none;
                }
            }

            input {
                width: 300px;
            }

            &Title {
                margin-bottom: 5px;
            }
        }
    }

    &IngredientsTable {
        display: flex;
        flex-direction: column;

        &Header {
                padding: 0 15px;
        }

        &Title {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            padding: 16px 0;
            border-bottom: 1px solid $dark-input-border;

            @include breakpoint(lg) {
                width: 100%;
            }

            span {
                @include fontWeight(large);
                @include texts(large);

                text-align: center;

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

    &Textarea {
        border: 1px solid $dark-input-border;
        border-radius: 5px;
        display: flex;
        margin-bottom: 20px;
        height: 85px;

        &Label {
            @include texts(large);
            @include fontWeight(large);
            margin: 15px 10px;
        }

        textarea {
            margin: 15px 10px;
            width: 80%;
        }
    }

    &Title {
        display: flex;
        align-items: center;

        &Header {
            margin-left: 30px;
        }
    }
}
