.content {
    &Icon {
        margin: 0 5px;
        cursor: pointer;

        &Disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &Content {
            border-radius: 10px;
            height: 20px;
            background: lightgray;
            padding: 5px;
            opacity: 1;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &EmptyIcon {
        width: 40px;
    }
}
