@import "../../../../src/assets/styles/grid.modules";
@import "../../../../src/assets/styles/mixins.modules";

.samples {
    margin-top: 20px;

    &Searcher {
        justify-content: flex-end;
        display: flex;
    }

    &Header {
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 15px;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);

            margin: 0 4px;
        }
    }

    &List {
        &ItemWrapper {
            display: flex;
            min-height: 34px;
            background: $first-tab-item-color;
            border: 1px solid $first-tab-item-color;
            color: $tab-item-color;
            align-items: center;

            @include fontWeight(normal);
            @include texts(big);

            &:nth-child(odd) {
                background: $second-tab-item-color;
            }

            &:hover {
                @include transition();

                cursor: pointer;
                color: black;
                background: $tab-item-hover;
                opacity: 0.95;
                border: 1px solid lightgrey;
            }

            span {
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0 4px;
            }
        }
    }

    &Lp {
        width: 3%;
        margin: 0 5px 0 10px !important;
    }

    &Name {
        @include fontWeight(bolded);

        width: 15%;
        display: flex;
        align-items: center;

        &Icon {
            width: 15px;
            height: 15px;
            background: white;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    &Supplier {
        width: 12%;
    }

    &Placement {
        width: 12%;
    }

    &Date {
        width: 7%;
    }

    &User {
        width: 16%;
    }

    &Description {
        width: 30%;
    }

    &Pagination {
        margin-top: 50px;
    }

    &Title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Date {
            display: flex;
            align-items: center;

            span {
                padding: 0 10px;
            }
        }
    }
}
