@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.content {
    &Icon {
        margin: 0 5px;
        cursor: pointer;

        &Disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &Content {
            border-radius: 10px;
            height: 20px;
            background: lightgray;
            padding: 5px;
            opacity: 1;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &Wrapper {
        display: flex;
        align-items: center;
        min-height: 52px;

        &Gap {
            margin-left: 145px;
            margin-right: 10px;
        }
    }

    &Hide {
        display: none;
    }

    &TextArea {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid $dark-input-border;
        display: flex;
        align-items: center;
        background: #2c2c30;

        textarea {
            width: 47%;
            margin-left: 11%;
            margin-right: 10px;
        }
    }
}
