.select {
    &Content {
        width: 200px !important;
        height: 33px !important;
    }

    &Wrapper {
        display: flex;
        flex-direction: column;
        position: relative;

        select {
            display: none;
        }
    }

    &Error {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 33px;
        font-size: 12px;
        color: red;
        text-align: left;
        padding-left: 5px;
    }
}
