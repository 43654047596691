@import "../../../assets/styles/mixins.modules";

.header {
    display: flex;
    flex-direction: column;

    &Title {
        @include texts(2x-large);

        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
    }
}
