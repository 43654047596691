@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.recipe {
    &Header {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        @include breakpoint(lg) {
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 0;
        }

        @media print {
            background: lightgrey;
            width: 100%;
        }

        &Wrapper {
            padding: 10px;
            display: flex;
            align-self: center;
            align-items: center;

            @media print {
                height: 40px;
                padding: 0;
            }

            div {
                @include fontWeight(normal);
                font-size: rem-calc(16);

                @media print {
                    @include fontWeight(large);
                    margin-top: 2px;
                    margin-left: 10px;
                    font-size: rem-calc(11);
                }
            }

            span {
                @include fontWeight(bolded);
                font-size: rem-calc(18);
                margin-left: 5px;

                @media print {
                    @include fontWeight(large);

                    font-size: rem-calc(14);
                }
            }

            &Name {
                width: 100%;
                border: 1px solid var(--input-border);

                @include breakpoint(lg) {
                    width: 42%;
                }

                @media print {
                    border: 1px solid black;
                }

                a {
                    text-decoration: underline !important;
                }
            }

            &Number {
                width: 100%;
                border: 1px solid var(--input-border);

                @include breakpoint(lg) {
                    width: 30%;
                }

                @media print {
                    border: 1px solid black;
                }
            }

            &Category {
                width: 100%;
                border: 1px solid var(--input-border);

                @include breakpoint(lg) {
                    width: 28%;
                    justify-content: center;
                }

                @media print {
                    border: 1px solid black;
                }
            }
        }

        &Value {
            @include fontWeight(bolded);
            @include texts(x-large);
        }
    }
}
