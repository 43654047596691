.changer {
    border-radius: 10px;
    background: lightgray;
    height: 12px;
    padding: 5px;
    cursor: pointer;

    &Up {
        transform: rotate(180deg);
    }

    &Disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
