$font-family-primary: 'Montserrat', sans-serif;

$black: #000;
$white: #ffffff;
$green: #188604;
$success-notification-color: #0f5f00;
$alert-notification-color: #d20300;
$warning-notification-color: yellow;

$font-primary: var(--font-primary);
$font-primary--hover: #9898a6;
$font-secondary: $black;
$font-secondary--hover: #585858;
$font-label: #878787;
//$dark-bg-color: #202124;
$menu-background: var(--menu-background);
$dark-bg-color: var(--dark-bg-color);
$iconBackground: var(--icon-background);

$dark-input-bg: rgba(68, 70, 76, 0.84);
$dark-input-bg-focus: rgba(68, 70, 76, 1);
$dark-input-border: var(--input-border);
$dark-input-shadow: #16181d;
$dark-placeholder-label: #cacaca;
$dark-nice: #22252c;

$ingredientBackground: #252628;

$main-padding-vertical: 30px;

$base: 16;
$columns: 12;
$gutter-width: 1.875rem;
$negative-gutter: calc((#{$gutter-width} * 0.5) * -1);
$negative-double-gutter: calc((#{$gutter-width}) * -1);
$half-gutter-width: calc((#{$gutter-width} * 0.5));
$quarter-gutter-width: calc((#{$gutter-width} * 0.25));


$table-header: #384051;
$first-tab-item-color: #ffffff;
$second-tab-item-color: #f6f6f6;
$tab-item-color: #545454;
$tab-item-hover: #f6f6f6;

$mobile: 47.9375;
$md-min: 48;
$md: 74.875;
$lg-min: 74.9375;
$lg: 100;
$xlg-min: 89, 94;
$xlg: 90;
$xxlg: 120;
$xxlg-min: 119, 9;

$screen-mobile: #{$mobile}rem;
$screen-md-min: #{$md-min}rem;
$screen-md: #{$md}rem;
$screen-lg-min: #{$lg-min}rem;
$screen-lg: #{$lg}rem;
$screen-xlg-min: #{$xlg-min}rem;
$screen-xlg: #{$xlg}rem;
$screen-xxlg-min: #{$xxlg-min}rem;
$screen-xxlg: #{$xxlg}rem;

$container-lg: #{$lg}rem;

$rc-slider-rail-color: #9d9d9d;

$filters-header-background: #646464;
$select-separator: #ededed;

$brand-section-height: 246px;
$brand-section-margin: 30px;

$facebook-background: #3a5897;

:export {
  breakpoint: $mobile * $base; // rem * base
  tabletBreakpoint: $md * $base;
}

$editGreenButton: #28a745;
$deleteRedButton: #e87979;
$buttonDefault: var(--button-default);
