@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.header {
    flex-direction: column;
    display: none;

    @media print {
        display: flex;
        width: 100%;
    }

    &Production {
        @include texts(large);
        @include fontWeight(bolded);

        display: flex;
        justify-content: center;
        margin: 20px;
    }

    &Info {
        display: flex;
        justify-content: space-between;
    }

    &Left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 10px;

        &Item {
            display: flex;

            @include texts(big);

            span {
                margin-left: 4px;

                @include fontWeight(large);
            }
        }
    }

    &Right {
        margin-right: 0;
    }
}

.production {
    display: none;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;

    @media print {
        display: flex;
        width: 100%;
    }

    &Label {
        @include fontWeight(bolded);

        width: 100%;
        display: flex;
        background: lightgrey;
        justify-content: center;
        border: 1px solid black;
        height: 40px;
        align-items: center;
    }

    &Items {
        display: flex;
        width: 100%;
        border: 1px solid black;

        div {
            @include texts(big);

            padding-top: 3px;
            padding-left: 3px;
            height: 40px;
        }

        &Name {
            width: 40%;
            border-right: 1px solid black;
        }

        &StartDate {
            width: 35%;
            border-right: 1px solid black;
            border-left: 1px solid black;
        }

        &EndDate {
            width: 35%;
            border-left: 1px solid black;
        }
    }
}
