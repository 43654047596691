@import "../../../src/assets/styles/grid.modules";
@import "../../../src/assets/styles/mixins.modules";

.recipes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    margin-bottom: 50px;
}
