.users {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    &Button {
        width: 20%;
        margin-top: 20px;
    }

    &List {
        width: 70%;
    }

    &New {
        width: 30%;
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-top: 28px;
    }
}
