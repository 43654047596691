@import "grid.modules";
@import "button.modules";

html,
body {
    background: $dark-bg-color;
}

:root {
    --dark-bg-color: #202124;
    --menu-background: rgba(56, 64, 81, 0.7);
    --font-primary: white;
    --button-default: lightgray;
    --icon-background: lightgrey;
    --input-border: #bdc6d8;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: $white;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input,
select {
    border-radius: 5px;
    border: 0;
    background-color: $dark-input-bg;
    border-bottom: 1px solid $dark-input-border;
    text-align: left;
    padding-left: 5px;
    height: 30px;
    outline: none;
    color: $white;
    width: auto;
    font-weight: 600;

    &::placeholder {
        color: $dark-placeholder-label;
        font-weight: 600;
    }

    &:focus {
        background: $dark-input-bg-focus;
        box-shadow: $dark-input-shadow 3px 3px 3px;
    }
}

textarea {
    border-radius: 5px;
    border: 0;
    text-align: left;
    background-color: $dark-input-bg;
    border-bottom: 1px solid $dark-input-border;
    min-height: 30px;
    outline: none;
    color: $white;
    width: auto;
    padding: 10px;
    font-weight: 600;

    &::placeholder {
        color: $dark-placeholder-label;
        font-weight: 600;
    }

    &:focus {
        background: $dark-input-bg-focus;
        box-shadow: $dark-input-shadow 3px 3px 3px;
    }
}

button {
    height: 50px;
    border-radius: 5px;
    border: none;
    min-width: 100px;
    background: lightgray;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }
}

a {
    color: $font-primary;
    text-decoration: none;

    &:hover {
        @include transition();

        color: $font-primary--hover;
    }
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.react-notification-root {
    position: fixed;
    z-index: 9000;
    pointer-events: none;
    width: 100%;
    right: 0;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}

.pagination > .active > a {
    background-color: #b0b5b6ff;
    border-color: #b0b5b6ff;
    color: #fff;
}

.pagination > li > a {
    border: 1px solid #b0b5b6ff;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #b0b5b6ff;
    border-color: #b0b5b6ff;
    outline: none;
}

.pagination > li > a,
.pagination > li > span {
    color: #b0b5b6ff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: unset;
}

.icon-button {
    margin-left: 20px;
    height: 20px;

    img {
        width: 20px;
        height: 20px;
    }
}
