@import "../../../src/assets/styles/grid.modules";

.history {
    width: 100%;

    &Header {
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 15px;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);

            margin: 0 4px;
        }
    }

    &Index {
        flex: 0.2;
        padding-right: 15px;
    }

    &Name {
        @include fontWeight(bolded);

        flex: 2;
    }

    &Date {
        flex: 0.8;
        display: flex;
        align-items: center;

        &Icon {
            width: 15px;
            height: 15px;
            background: white;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    &User {
        flex: 1.5;
    }

    &DescriptionHeader {
        flex: 2.6;
    }

    &Description {
        @include texts(large);

        flex: 2.6;
    }

    &List {
        &ItemWrapper {
            display: flex;
            min-height: 34px;
            background: $first-tab-item-color;
            border: 1px solid #ffffff;
            color: $tab-item-color;
            align-items: center;

            @include fontWeight(normal);
            @include texts(big);

            &:nth-child(odd) {
                background: $second-tab-item-color;
            }

            &:hover {
                @include transition();

                cursor: pointer;
                color: black;
                background: $tab-item-hover;
                opacity: 0.95;
                border: 1px solid lightgrey;
            }

            span {
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0 4px;
            }
        }
    }

    &Title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Date {
            display: flex;
            align-items: center;

            span {
                padding: 0 10px;
            }
        }
    }
}
