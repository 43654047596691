@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.recipe {
    &List {
        &Item {
            display: flex;
            min-height: 30px;
            width: 100%;
            justify-content: center;

            div {
                @include fontWeight(large);
                @include texts(large);

                display: flex;
                justify-content: center;
                border: 1px solid var(--input-border);
                padding: 10px;

                @media print {
                    @include fontWeight(medium);
                    @include texts(medium);

                    display: flex;
                    border: 1px solid black;
                    padding: 2px 10px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }

            &Position {
                width: 3%;
            }

            &Name {
                width: 47%;
                justify-content: flex-start !important;

                a {
                    @media print {
                        color: black;
                    }
                }
            }

            &Value {
                width: 8%;
                align-items: center;
                font-size: rem-calc(12) !important;

                @include breakpoint(lg) {
                    font-size: rem-calc(16) !important;
                }

                @media print {
                    font-size: rem-calc(12) !important;
                }
            }

            &Description {
                @include fontWeight(normal);
                @include texts(big);

                min-height: 35px;
                display: flex;
                border: 3px solid var(--input-border);
                padding: 15px 30px;

                @media print {
                    border: 1px solid black;
                    background: white;
                    font-weight: bolder;
                    font-size: 12px;
                    padding: 10px 15px;
                }
            }

            &EmptyDescription {
                @include fontWeight(normal);
                @include texts(big);

                min-height: 35px;
                display: flex;
                padding: 10px;
                background: $dark-bg-color;

                @media print {
                    background: white;
                    min-height: 25px;
                }
            }

            &Mas {
                width: 10%;

                @media print {
                    width: 8% !important;
                }

                &Print {
                    display: none !important;

                    @media print {
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &Button {
                    padding: 5px 10px !important;

                    button {
                        @media print {
                            display: none;
                        }
                    }
                }
            }

            &Density {
                width: 10%;

                @media print {
                    display: none !important;
                }
            }

            &Add {
                width: 10%;
                @media print {
                    width: 12%;
                }
            }

            &Correct {
                width: 10%;
            }

            &Sum {
                width: 10%;
                align-items: center;

                @media print {
                    align-items: unset;
                    width: 8%;
                    padding: 0;
                    justify-content: unset;
                    height: 30px;
                    background: var(--input-border);
                }

                input {
                    width: 45px !important;
                    @media print {
                        display: none;
                        width: 10px !important;
                    }
                }

                @include breakpoint(lg) {
                    input {
                        width: 80px !important;
                    }
                }

                form {
                    height: 30px;

                    @media print {
                        width: unset;
                        display: none;
                    }
                }
            }
        }
    }

    &Odd {
        &:nth-child(odd) {
            background: #2c2c30;

            @media print {
                background: #f1efef;
            }
        }
    }

    &Input {
        input {
            width: 100px;
            height: 30px;
        }
    }

    &SumPrint {
        display: none;

        @media print {
            display: block;
            align-self: center;
            align-items: center;
            justify-content: center;
            font-weight: 900;
        }
    }
}

