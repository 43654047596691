@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.recipe {
    &Footer {
        padding-top: 20px;
        margin-top: 30px;
        width: 100%;
        position: relative;

        &Deafult {
            @include texts(large);

            border: 1px solid var(--input-border);
            border-radius: 5px 5px 0 0;
            display: flex;
            min-height: 60px;

            @media print {
                border: 1px solid black;

                @include texts(small);
            }

            &Title {
                @include fontWeight(large);
                width: 12%;
            }

            &Description {
                width: 88%;
                justify-content: flex-start;
            }

            span {
                padding: 10px;
            }
        }
    }

    &Final {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media print {
            flex-direction: row!important;
        }

        @include breakpoint(lg) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: center;
        }

        &Wrapper {
            padding: 10px;
            width: 100%;
            border: 1px solid var(--input-border);

            @media print {
                border: 1px solid black;
                width: 25%;
            }

            @include breakpoint(lg) {
                width: 25%;
            }

            span {
                @include texts(large);

                @media print {
                    @include texts(small);
                }

                &:first-child {
                    @include fontWeight(large);

                    margin-right: 8px;
                }
            }
        }
    }

    &Last {
        @include fontWeight(large);
        @include texts(medium);

        display: flex;
        justify-content: flex-end;
        margin-top: 2px;

        span {
            &:first-child {
                margin-right: 8px;
            }
        }
    }
}
