@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.width {
    width: 100%;
}

.header {
    display: flex;
    align-items: center;

    &Title {
        margin-left: 30px;
    }
}
