@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.autocomplete {
    z-index: 10;
    position: absolute;
    border: 1px wheat solid;
    top: 34px;
    width: 92%;
    border-radius: 5px;

    &Value {
        margin: 0 !important;
    }

    li {
        background: #24222f;
        border-radius: 5px;
        border: 1px solid #505b67;

        &:nth-child(odd) {
            background: #3a4958;
        }

        &:hover {
            @include transition();

            cursor: pointer;
            color: $font-primary--hover;
            background: #24303c;
        }
    }
}
