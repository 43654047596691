@import "../../../src/assets/styles/variables.modules";
@import "../../../src/assets/styles/mixins.modules";

.notification {
    &Wrapper {
        position: unset;

        @include breakpoint(lg) {
            position: relative;
        }
    }

    &Text {
        @include texts(big);
        @include fontWeight(medium);

        box-shadow: 0 0 10px rgba($black, 0.2);
        font-size: 0.75rem;
        position: relative;
        display: block;
        height: 40px;
        background: lightgrey;
        border-radius: 5px;
        padding: 10px;
        color: $black;
        width: 30%;
        line-height: 1.4;
        margin-right: 10px;

        a {
            text-decoration: underline;
            color: $black;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 100%;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid transparent !important;
            border-left: 10px solid lightgrey;
        }

        &::before {
            margin-right: 1px;
            border-right: 10px solid lightgrey;
        }
    }
}
