@import "../../../assets/styles/variables.modules";
@import "../../../assets/styles/mixins.modules";

.wrapper {
    padding: 0 10px;
    width: 100%;

    &Button {
        width: 100%;
        margin-top: 20px;
    }

    &Name {
        text-align: center;
        margin-bottom: 10px;
        font-size: rem-calc(30);
        @include fontWeight(bolded);
    }

    &Email {
        text-align: center;
    }
}
