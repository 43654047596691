.inputWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 5px;

    &Label {
        font-size: 12px;
        text-align: left;
        padding-left: 5px;
    }

    &Error {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: red;
        text-align: left;
        padding-left: 5px;

        &Recipe {
            position: absolute;
            bottom: 0;
            font-size: 12px;
            color: red;
            text-align: left;
            padding-left: 5px;
            top: 33px;
        }
    }
}

.checkboxInputWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;

    input {
        height: 20px;
    }

    &Error {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 18px;
        font-size: 12px;
        color: red;
        text-align: left;
        padding-left: 5px;
    }
}

.textAreaInputWrapper {
    position: relative;

    &Error {
        position: absolute;
        top: 60px;
        left: 0;
        font-size: 12px;
        color: red;
        text-align: left;
        padding-left: 5px;
    }
}
