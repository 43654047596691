@import "../../../src/assets/styles/grid.modules";
@import "../../../src/assets/styles/mixins.modules";

.list {
    margin-top: 15px;

    &Header {
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        //background: #362f4b;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);

            margin: 0 4px;
        }
    }

    &Index {
        width: 5%;

        @include breakpoint(lg) {
            width: 2%;
        }
    }

    &Number {
        @include fontWeight(bolded);

        width: 30%;

        @include breakpoint(lg) {
            width: 20%;
        }
    }

    &Name {
        width: 40%;

        @include breakpoint(lg) {
            width: 30%;
        }
    }

    &Category {
        width: 18%;
    }

    &Counts {
        width: 3%;
    }

    &Created {
        width: 7%;
        display: flex;
        align-items: center;
    }

    &Edited {
        width: 25%;

        @include breakpoint(lg) {
            width: 7%;
        }
    }

    &Author {
        width: 12%;
    }

    &Icon {
        width: 15px;
        height: 15px;
        background: white;
        margin-left: 5px;
        cursor: pointer;
    }

    &History {
        @include texts(big);

        padding: 5px;
        width: 50%;
        color: $tab-item-color;
        margin: 5px;
        display: flex;
        flex-direction: row;

        &:hover {
            @include transition();

            color: $black;
        }

        &Action {
            width: 20%;
            &Title {
                @include fontWeight(large);
                @include texts(big);

                padding: 0 10px;
            }
        }

        &Description {
            display: flex;

            span {
                font-weight: 400;
                color: rgba(74, 50, 134, 0.74);
            }

            &Title {
                @include fontWeight(large);
                @include texts(big);

                padding: 0 10px;
                margin-left: 18px;
            }
        }
    }

    &List {
        &Item {
            display: flex;
            min-height: 34px;
            color: $tab-item-color;
            align-items: center;

            @include fontWeight(normal);
            @include texts(big);

            span {
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0 4px;
            }

            &:hover {
                @include transition();

                color: $black;
            }
        }
    }

    &Wrapper {
        background: $first-tab-item-color;
        border: 1px solid $first-tab-item-color;

        &:nth-child(odd) {
            background: $second-tab-item-color;
        }

        &:hover {
            @include transition();

            cursor: pointer;
            color: $black;
            background: $tab-item-hover;
            opacity: 0.95;
            border: 1px solid lightgrey;
        }
    }

    &Title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Date {
            display: flex;
            align-items: center;

            span {
                padding: 0 10px;
            }
        }
    }
}

.wrapper {
    height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: $white;
    }
}
