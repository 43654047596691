@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid $dark-input-border;
    height: 100px;

    @include breakpoint(lg) {
        height: auto;
    }

    &Autocomplete {
        &Wrapper {
            position: relative;
        }

        &Icon {
            height: 20px;
        }
    }

    &Summary {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(lg) {
            flex-direction: row;
            padding: 16px 0;
        }

        &Wrapper {
            display: flex;
            width: 100%;
            justify-content: center;

            @include breakpoint(lg) {
                justify-content: unset;
            }

            &PercentSum {
                display: flex;
                flex-direction: column;

                &Title {
                    align-self: center;
                    @include texts(big);
                }

                &Totality {
                    @include texts(large);

                    align-self: center;
                    margin-top: 5px;
                }
            }

            &ContentSum {
                display: flex;
                flex-direction: column;
                width: 20%;

                &Title {
                    align-self: center;
                    margin-bottom: 5px;
                    @include texts(big);
                }
            }
        }

        &Density {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;

            @include breakpoint(lg) {
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 20px;
                margin-left: 30px;
            }

            &Second {
                margin-left: 10px;
            }

            div {
                justify-content: center;
                align-items: center;
            }

            &InputWrapper {
                width: 80%;

                @include breakpoint(lg) {
                    width: 50%;
                }
            }

            input {
                width: 80%;
            }
        }
    }

    &Inputs {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 0;
        position: relative;

        span,
        div {
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include breakpoint(lg) {
                flex-direction: row;
            }
        }

        input {
            width: 100%;
        }

        &Index {
            width: 20px;
        }
    }

    &TextArea {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid $dark-input-border;
        display: flex;
        align-items: center;
        background: #2c2c30;

        textarea {
            width: 47%;
            margin-left: 11%;
            margin-right: 10px;
        }
    }

    &Buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        height: 35px;

        @include breakpoint(lg) {
            width: 20%;
            justify-content: flex-end;
        }
    }

    &Icon {
        margin: 0 5px;
        cursor: pointer;

        &Disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &Content {
            border-radius: 10px;
            height: 20px;
            background: lightgray;
            padding: 5px;
            opacity: 1;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &EmptyIcon {
        width: 40px;
    }

    &PriceWrapper {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        align-self: center;
    }
}

.form {
    &PositionChanger {
        width: 7%;
    }

    &EmptyPositionChanger {
        width: 7%;
        padding-right: 15px;
    }

    &Index {
        width: 3%;
        margin-left: -10px;
    }

    &Name {
        width: 30%;
        padding-right: 2% !important;
    }

    &Value {
        width: 10%;
    }

    &Sum {
        width: 10%;
    }

    &Density {
        width: 10%;
    }

    &Price {
        width: 10%;
        justify-content: center;
        display: flex;
    }

    &Button {
        width: 13%;
    }
}
