@import "../../../src/assets/styles/mixins.modules";

.newCategory {
    position: relative;

    &Form {
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
            margin: 0 10px;
        }
    }

    &Title {
        @include texts(large);
    }

    &Input {
        margin: 0 20px;
        width: 300px;
    }
}
