@import "../../assets/styles/variables.modules";
@import "../../assets/styles/mixins.modules";

.wrapper {
    cursor: pointer;
    margin-right: 10px;
    padding-top: 5px;
}

.switch {
    @include transition();

    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 23px;
    background-color: grey;
    border-radius: 20px;

    &:after {
        @include transition();

        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $white;
        top: 4px;
        left: 4px;
    }
}

.checkbox {
    display: none;

    &:checked + .switch:after {
        @include transition();

        transform: translateX(17px);
    }

    &:checked + .switch {
        @include transition();

        background-color: $black;
    }

    &:checked + .disabled {
        background-color: rgba(0, 0, 0, 0.37);
    }
}
