@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.content {
    margin-top: 20px;

    &Wrapper {
        display: flex;
        align-items: center;
        border: 1px solid var(--input-border);
        height: 50px;
        text-align: center;
        background: #22252c;

        &Input {
            margin-bottom: 0;
            width: 600px;
        }

        &Label {
            margin: 0 20px;
            width: 100px;
        }
    }
}

.buttonWrapper {
    display: flex;
    align-items: center;

    &Label {
        margin: 0 20px;
        width: 100px;
    }

    &Add {
        height: 32px;
        margin-left: 20px;
    }

    &List {
        ul {
            height: 200px;
            width: 300px;
            background-color: #44464c;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--input-border);
    height: 100px;
    text-align: center;
    background: #22252c;
    margin: 20px 0;
    justify-content: space-evenly;
}

.recipe {
    display: flex;
    margin-left: 20px;

    &List {
        margin-left: 10px;
        font-weight: bold;

        &Label {
            padding-left: 10px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
