@import "../../../src/assets/styles/grid.modules";
@import "../../../src/assets/styles/mixins.modules";

.users {
    &TopSection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &Title {
        margin-left: 70px;
    }

    &Header {
        margin-top: 10px;
        display: flex;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: $table-header;
        height: 40px;
        align-items: center;

        span {
            @include fontWeight(bolded);
            @include texts(big);
        }
    }

    &Index {
        width: 3%;
        margin-left: 5px;
    }

    &User {
        width: 16%;
    }

    &Email {
        width: 22%;
    }

    &Roles {
        width: 30%;
    }

    &Created {
        width: 10%;
    }

    &Blocked {
        width: 11%;
    }

    &Edit {
        width: 5%;
        button {
            display: flex;
        }

        button {
            @include transition();

            border: none;
            background: none;
            color: #000;
            text-decoration: none;
            font-weight: 700;
            padding: 0;

            &:hover {
                @include transition();

                text-decoration: underline;
            }
        }
    }

    &List {
        display: flex;
        color: $tab-item-color;
        padding: 5px 0;
        background: $first-tab-item-color;
        border: 1px solid $first-tab-item-color;

        @include fontWeight(normal);
        @include texts(big);

        &:nth-child(odd) {
            background: $second-tab-item-color;
        }

        &:hover {
            @include transition();

            cursor: pointer;
            color: black;
            background: $tab-item-hover;
            opacity: 0.95;
            border: 1px solid lightgrey;
        }

        &Roles {
            &Wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                input {
                    height: 25px;
                    margin: 2px 0 0 5px;
                    color: black;
                }
            }
        }
    }
}
