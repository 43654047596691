@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.wrapper {
    padding: 15px;
    align-self: center;
    min-width: 320px;
}

.formWrapper {
    @include texts(2x-large);

    display: flex;
    align-items: stretch;
    flex-direction: column;
    text-transform: capitalize;

    &Inputs {
        background: #384051;
        border-radius: 10px;
        padding: 30px 20px 20px;

        div:first-child {
            margin: 7px 0 18px;
        }
    }

    label {
        padding: 0 0 10px 2px;
    }

    input {
        height: 35px;
        border-radius: 5px;
        box-shadow: 0px 4px 5px 0px #000;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        //-webkit-box-shadow: 0 0 0px 1000px #f5f4d5 inset;
        background-color: $dark-input-bg !important;
        //box-shadow: $dark-input-shadow 3px 3px 3px;
    }

    button {
        margin: 30px 0;
    }
}
