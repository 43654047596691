@import "assets/styles/variables.modules";
@import "assets/styles/mixins.modules";

.App {
  text-align: center;
}

.content {
  @include texts(2x-large);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-bg-color;
  width: 100%;
  height: 100vh;
  color: $font-primary;
}

.contentColumn {
  @include texts(2x-large);

  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $dark-bg-color;
  color: $font-primary;
}
