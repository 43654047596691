.checkbox {
    display: flex;
    label input {
        display: none; /* Hide the default checkbox */
    }

    label {
        display: flex;
        align-items: center;

        div {
            width: 190px;
        }
    }

    /* Style the artificial checkbox */
    label span {
        height: 10px;
        width: 10px;
        border: 1px solid #384051;;
        display: inline-block;
        position: relative;
        margin-left: 5px;
    }

    /* Style its checked state...with a ticked icon */
    [type="checkbox"]:checked + span:before {
        content: "\2714";
        position: absolute;
        top: -3px;
        left: 0;
        color: #384051;
    }
}
