@import "../../../../src/assets/styles/grid.modules";
@import "../../../../src/assets/styles/mixins.modules";

.header {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    @include breakpoint(lg) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &Title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }

    &Count {
        margin-right: 30px;
        display: flex;

        @include breakpoint(lg) {
            margin-left: 30px;
            margin-right: 0;
        }
    }

    &SearchBar {
        display: flex;
        flex-direction: row;

        &InputField {
            display: flex;
            align-self: center;
            align-items: center;
            padding: 0 7px;

            @include breakpoint(lg) {
                padding: 0 10px;
            }

            span {
                margin-right: 10px;
            }
        }

        &Select {
            display: flex;
            align-self: center;
            align-items: center;

            select {
                height: 33px;
                padding: 0 20px;
            }
        }
    }
}
