@import "../../../src/assets/styles/variables.modules";
@import "../../../src/assets/styles/mixins.modules";

.detail {
    width: 100%;
    &Name {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &Title {
            @include fontWeight(normal);
            @include texts(2x-large);
        }

        &Ingredient {
            @include fontWeight(bolded);
            @include texts(2x-large);

            padding-left: 10px;
        }
    }

    &Error {
        @include fontWeight(large);
        @include texts(medium);

        position: absolute;
        left: 15px;
        top: 42px;
        color: red;
    }

    &Button {
        width: 350px;
        height: 50px;
        text-align: center;
        justify-content: center;
        display: flex;
        align-self: auto;
        align-items: center;
        margin: 15px;
    }

    &FormWrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 50px;
        margin: 15px 0;
        align-items: center;

        &Disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        input {
            margin: 0 10px;
            width: 222px;
        }

        form {
            position: relative;

            button {
                margin: 0 10px;
            }
        }

        button {
            margin: 0 10px;
        }
    }

    &BackButton {
        margin: 15px 0;
        width: 150px;
        height: 35px;
        border-radius: 5px;
    }
}
